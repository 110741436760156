










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getNameByEmail } from '@/utils/users';

@Component({

  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: true }) tasqId!: string;

  get tasq(): TasqJob|undefined {
    return tasqsListModule.tasqById(this.tasqId);
  }

  get fullName(): string {
	  return getNameByEmail(this.tasq?.username);
  }

  goToTasqPage() {
    // this.$router.replace({
    //   name: 'Tasqs',
    //   params: {
    //     id: this.tasq?.id || '',
    //   },
    //   query: {
    //     type: 'id',
    //   }
    // });
    tasqsListModule.setActiveTasq(this.tasq?.id || '');
    tasqsListModule.setKanbanSelectedTasq(this.tasq?.id || '');
    this.$emit('set-hide-well');
  }
}

function isTasqActive(id) {
  if (window.location.href.includes(id)) {
    return true;
  }
  return false;
}
